.breadcrumb {
  user-select: none;

  .breadcrumb__title {
    font-size: 18px;
    color: #323846;
    margin-right: 5px;
    margin-left: 9px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
      font-weight: 500;
    }
  }
}
