@import './bootstrap-grid/grid.scss';
@import './reset.scss';
@import './vars.scss';
@import './utilities.scss';

body {
  color: var(--color-text-light);
  font-family: Geometria;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  line-height: 20px;
  font-weight: 400;
  color: #515c72;
  font-size: 14px;
}
