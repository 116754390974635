.main-toolbar {
  background: #f7fafc;
  height: 51px;
  min-height: 51px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 44px 0 36px 0;
  box-shadow: inset 0 -1px 0 #dcdee6;

  .main-toolbar__search {
    width: 300px;
    padding: 0 20px;
  }
  .main-toolbar__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .main-toolbar__tabs {
    display: flex;
    flex-direction: row;
    list-style: none;
    li {
      margin-right: 20px;
      cursor: pointer;
      &.active {
        color: #4eda77;
      }
    }
  }
  .main-toolbar__button {
    user-select: none;
    padding: 0 20px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: #6a7b95;

    &.active {
      font-weight: 500;
    }

    &.active.unassigned {
      color: #fa9269;
    }

    &.active.in-work {
      color: #4eda77;
    }

    &.active.done {
      color: #6a7b95;
    }

    &.active.default {
      color: #4eda77;
    }
  }
}
