.menu-subheader {
  font-size: 16px;
  color: #8792ab;
  padding: 12px 0;
  padding-left: 5px;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  text-decoration: none;
  line-height: 1.4;
  font-weight: 500;
  position: relative;

  &.is-last {
    padding-bottom: 20px;
  }

  &.with-menu {
    width: 100%;

    &.forceActive {
      color: #191b23;
    }
  }

  &.without-menu {
    &.active,
    &.router-link-active {
      color: #191b23;
    }
  }

  &__title {
    padding: 12px 0 0 44px !important;
    position: relative;
  }
}
