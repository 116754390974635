.topbar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px;
  border-bottom: 1px solid #e9ebf3;

  .topbar-left {
    position: relative;
    top: 5px;
    display: flex;
    align-items: center;
  }

  .topbar-right {
    position: relative;
    top: 5px;
    display: flex;
    align-items: center;
  }
}
